<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="品目一覧" />
    <AppSideMenu />
    <main class="l-main">
      <!-- 検索フォーム ============================================================ -->
      <div>
        <div class="c-searchMenu__wrap">
          <div v-if="isDisplaySearch" class="c-searchMenu">
            <div class="l-flex">
              <div v-if="shorten().length === 0">
                <div class="title icon">条件なし</div>
              </div>
              <template v-else v-for="(s, index) in shorten()">
                <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
                  {{ s.key }}<div class="data"> : {{ s.value }}</div>
                </div>
              </template>
            </div>
            <div class="l-flex">
              <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
            </div>
          </div>
        </div>
        <div class="l-full-white l-area">
          <div class="l-box l-flex">
            <h3 class="c-lead-s-black icon">検索</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols center search-wrap">
                <Selectbox
                    class="mid"
                    placeholder="選択してください"
                    :value.sync="searchParams.item"
                ><template v-slot:label
                ><div class="c-inputWrap__label">
                  <label>廃棄物種類</label>
                </div></template
                >
                  <option value="">選択してください</option>
                  <option
                      v-for="item of jwnetItems"
                      :key="item.id"
                      :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
                <InputText
                    class="lg"
                    :value.sync="searchParams.keyword"
                    inputType="text"
                    placeholder="品目名称から検索できます"
                    :validation="validateMaxLen"
                    :validateArg="100"
                >
                <span class="c-inputWrap__label"
                ><label>CBA品目名称</label></span
                >
                </InputText>
              </div>
            </div>
          </div>
        </div>
        <div class="l-container">
          <div class="l-btns l-block-lg">
            <div class="c-btn secondary small" @click="resetSearch()">
              条件をリセット
            </div>
            <div class="c-btn primary small" @click="searchHandler()">検索</div>
          </div>
        </div>
      </div>

      <!-- 検索結果 ============================================================ -->
      <template v-if="isInit" v-cloak>
        <div class="l-container">
          <div class="l-lead-s title-wrap">
            <h1 class="c-lead">検索結果は検索ボタンをクリックした後に表示されます。</h1>
            <div class="c-btn secondary func add" @click="$router.push(`/cbaitem/input?jwnetItemId=${searchParams.item}`)">
              品目追加
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="l-container" v-for="list of searchItemList" :key="'item-' + list.jwnetItemInfo.id">
          <div class="l-lead-s title-wrap">
            <h1 class="c-lead">
              {{ list.jwnetItemInfo.name }}<span class="counter">({{ list.jwnetItemInfo.code }})</span>
            </h1>
            <div
                @click="$router.push(`/cbaitem/input?jwnetItemId=${list.jwnetItemInfo.id}`)"
                class="c-btn secondary func add"
            >
              品目追加
            </div>
          </div>

          <div class="c-infoList__wrap">
            <div class="c-accordionList" style="margin-bottom: 6rem !important;">
              <input
                  :id="'list-acc-' + list.jwnetItemInfo.id"
                  class="c-accordionList__cb"
                  type="checkbox"
              />
              <div class="c-accordionList__head">
                <div class="c-accordionList__cell">
                  <label
                      class="c-accordionList__lbl"
                      :for="'list-acc-' + list.jwnetItemInfo.id"
                  ></label>
                </div>
                <div class="c-accordionList__cell sm">CBA品目名称</div>
                <div class="c-accordionList__cell mid">備考</div>
              </div>
              <div
                  v-for="cbaItem in list.cbaItemList"
                  :key="'cba-item-' + cbaItem.cbaItemInfo.id"
                  class="c-accordionList__body"
              >
                <input
                    :id="
                    'cba-item-acc-' +
                    cbaItem.cbaItemInfo.id +
                    'list-acc-' +
                    list.jwnetItemInfo.id
                  "
                    class="c-accordionList__cb"
                    type="checkbox"
                />
                <div class="c-accordionList__head">
                  <div class="c-accordionList__cell">
                    <label
                        class="c-accordionList__lbl"
                        :for="
                        'cba-item-acc-' +
                        cbaItem.cbaItemInfo.id +
                        'list-acc-' +
                        list.jwnetItemInfo.id
                      "
                    ></label>
                  </div>
                  <div class="c-accordionList__cell sm">
                    {{ dottedText(cbaItem.cbaItemInfo.name, 16) }}
                  </div>
                  <div class="c-accordionList__cell mid text-dotted">
                    {{ cbaItem.cbaItemInfo.remarks | blankText }}
                  </div>
                  <div class="c-accordionList__cell right">
                    <input
                        type="checkbox"
                        :id="
                        'cba-item-' +
                        cbaItem.cbaItemInfo.id +
                        'list-' +
                        list.jwnetItemInfo.id
                      "
                        class="c-accordionList__acc"
                    />
                    <div class="c-accordionList__body">
                      <div
                          @click="$router.push(`/wastename/input?cbaitemId=${cbaItem.cbaItemInfo.id}`)"
                          class="c-accordionList__cell ml-1"
                      >
                        <div class="c-btn-icon add secondary sm"></div>
                      </div>
                    </div>
                    <div class="c-accordionList__body">
                      <div
                          @click="
                          $router.push(`/cbaitem/edit/${cbaItem.cbaItemInfo.id}`)
                        "
                          class="c-accordionList__cell ml-1"
                      >
                        <div class="c-btn-icon edit secondary sm"></div>
                      </div>
                    </div>
                    <div class="c-accordionList__body">
                      <div
                          @click="
                          deleteCbaItemHandler(
                            cbaItem.cbaItemInfo.id,
                            cbaItem.cbaItemInfo.name
                          )
                        "
                          class="c-accordionList__cell ml-1"
                      >
                        <div class="c-btn-icon delete secondary sm"></div>
                      </div>
                    </div>
                    <label
                        :for="
                        'cba-item-' +
                        cbaItem.cbaItemInfo.id +
                        'list-' +
                        list.jwnetItemInfo.id
                      "
                        class="c-accordionList__cell ml-1 c-accordionList__acc__lbl"
                    >
                      <div class="c-btn-icon some secondary sm"></div>
                    </label>
                  </div>
                </div>
                <div class="c-accordionList__body">
                  <div class="c-accordionList__row short">
                    <div class="c-accordionList__cell sm ml">廃棄物名称</div>
                    <div class="c-accordionList__cell mid">備考</div>
                  </div>
                  <div
                      v-for="item of cbaItem.wasteNameList"
                      :key="item.id"
                      class="c-accordionList__row"
                  >
                    <div class="c-accordionList__cell sm ml">{{ item.name }}</div>
                    <div class="c-accordionList__cell mid">
                      {{ item.remarks | blankText }}
                    </div>
                    <div class="c-accordionList__cell right">
                      <input
                          type="checkbox"
                          :id="'waste-' + item.id + 'list-' + list.jwnetItemInfo.id"
                          class="c-accordionList__acc"
                      />
                      <div class="c-accordionList__body">
                        <div
                            @click="$router.push(`/wastename/edit/${item.id}`)"
                            class="c-accordionList__cell ml-1"
                        >
                          <div class="c-btn-icon edit secondary sm"></div>
                        </div>
                      </div>
                      <div class="c-accordionList__body">
                        <div
                            @click="deleteWastehandler(item.id, item.name)"
                            class="c-accordionList__cell ml-1"
                        >
                          <div class="c-btn-icon delete secondary sm"></div>
                        </div>
                      </div>
                      <label
                          :for="
                          'waste-' + item.id + 'list-' + list.jwnetItemInfo.id
                        "
                          class="
                          c-accordionList__cell
                          ml-1
                          c-accordionList__acc__lbl
                        "
                      >
                        <div class="c-btn-icon some secondary sm"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </main>

    <!--CBA品目削除のモーダル-->
    <div v-if="isDisplayCBADelete" class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <div class="c-text l-container l-box">
            <ErrorMessage :errMsgs="errMsgs" />
            CBA品目を削除します。<br />よろしいですか？
          </div>
          <div class="l-btns">
            <div
                class="c-btn secondary small"
                @click="isDisplayCBADelete = false"
            >
              戻る
            </div>
            <div class="c-btn primary small" @click="deleteCbaItem()">OK</div>
          </div>
        </div>
      </div>
    </div>
    <!--廃棄物削除のモーダル-->
    <div v-if="isDisplayWasteDelete" class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <div class="c-text l-container l-box">
            <ErrorMessage :errMsgs="errMsgs" />
            廃棄物を削除します。<br />よろしいですか？
          </div>
          <div class="l-btns">
            <div
                class="c-btn secondary small"
                @click="isDisplayWasteDelete = false"
            >
              戻る
            </div>
            <div class="c-btn primary small" @click="deleteWaste()">OK</div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import validation from "@/mixin/validation";
import common from "@/mixin/common";
import callApi from "./mixins/callApi";
import { API_URL } from "@/const";

export default {
  name: "cbaitem-list",
  components: {
    InputText,
    Selectbox,
  },
  mixins: [callApi, common, validation],
  data() {
    return {
      isDisplaySearch: false,
      isInit: true,
      // 検索
      searchParams: {
        keyword: "",
        item: "",
      },
      cbaItemDrawer: [],
      wasteDrawer: [],
      cbaDeleteTmpId: "",
      cbaDeleteTmpName: "",
      wasteDeleteTmpId: "",
      wasteDeleteTmpName: "",
      isDisplayCBADelete: false,
      isDisplayWasteDelete: false,
      cbaItemAcc: [],
      errMsgs: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    // 廃棄物種類選択肢取得API
    this.getJwnetItemTypeApi();
  },
  methods: {
    resetSearch() {
      this.searchParams = {
        keyword: "",
        item: "",
      };
    },
    searchHandler() {
      this.isInit = false;
      this.getItemSearchApi();
    },
    deleteCbaItemHandler(id, name) {
      this.cbaDeleteTmpId = id;
      this.cbaDeleteTmpName = name;
      this.isDisplayCBADelete = true;
    },
    deleteCbaItem() {
      this.errMsgs = [];
      axios.put(API_URL.WASTEITEM.CBAITEM_EDIT + this.cbaDeleteTmpId, { cbaItemName: this.cbaDeleteTmpName, isActive: false })
          .then(() => {
            this.getItemSearchApi();
            this.isDisplayCBADelete = false;
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          });
    },
    deleteWastehandler(id, name) {
      this.wasteDeleteTmpId = id;
      this.wasteDeleteTmpName = name;
      this.isDisplayWasteDelete = true;
    },
    deleteWaste() {
      this.errMsgs = [];
      axios.put(API_URL.WASTEITEM.WASTENAME_EDIT + this.wasteDeleteTmpId, { wasteName: this.wasteDeleteTmpName, isActive: false })
          .then(() => {
            this.getItemSearchApi();
            this.isDisplayWasteDelete = false;
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          });
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    shorten() {
      const result = [];
      if (this.searchParams.item) result.push({ key: "廃棄物種類", value: this.jwnetItems.filter(j => j.id === this.searchParams.item)[0].name });
      if (this.searchParams.keyword) result.push({ key: "CBA品目名称", value: this.searchParams.keyword });

      return result;
    },
  },
};
</script>

<style scoped>
.title-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
